
import { server_function, server_url, load_data, login_user, register_user } from './server.js';

const isCordova = typeof window.cordova !== 'undefined';
const isPWA = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

const params = new URLSearchParams(window.location.search);

const image_path="https://ik.imagekit.io/4kcwvbvwg/IMG_CONTENT/";

let page_vars={};
for (const [key, value] of params.entries())
	page_vars[key]=value;

if(page_vars["token"])
{
	localStorage.kivi_session=page_vars["token"];
}

let page_lng="es";

if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    document.body.classList.add('ios');
}

let kivi;
const player={name:"Player1",values:{},current_test:0,test_loop:0,current_question:0,
			test_coins:0,test_correct:0,results:null};
const results={};
const apartat_progress={};

let audioContext=null;
const sounds={
		select:{},
		correct:{},
		incorrect:{},
		done:{},
		again:{}
};
let current_sound=null;

async function load_sound(url){
	try {
		const response=await fetch(url);
		arrayBuffer=await response.arrayBuffer();
		return(arrayBuffer);
	} catch (error) {
		return(null);
	}
}
async function play_sounds(audio)
{
	/*
	if(current_sound)
	{
		sounds[current_sound].el.pause();
		sounds[current_sound].el.currentTime=0;
	}
	sounds[audio].el.pause();
	sounds[audio].el.currentTime=0;
	sounds[audio].el.play();
	current_sound=audio;
	return;
	*/
	if(!audioContext)
		audioContext = new (window.AudioContext || window.webkitAudioContext)();

	//if(!audioContext) alert("Error audio context");

	if (audioContext.state === 'suspended')
	{
			//alert("Audio contest resume");
			await audioContext.resume();
	}

	if(!sounds[audio].buffer)
		sounds[audio].buffer=await audioContext.decodeAudioData(sounds[audio].data);

	const source = audioContext.createBufferSource();
	source.buffer = sounds[audio].buffer;
	source.connect(audioContext.destination);
	if (source.start) {
		source.start(0);
	} else if (source.play) {
		source.play(0);
	} else if (source.noteOn) {
		source.noteOn(0);
	}
}

function show_lng(lng)
{
	document.querySelectorAll(".lng").forEach(element=>{
		element.style.display = 'none';
	});
	document.querySelectorAll(".lng_"+lng).forEach(element=>{
		element.style.display = 'inline';
	});	
	page_lng=lng;
}

function lng_click(e)
{
	var lng=this.getAttribute("href");
	show_lng(lng);
	e.preventDefault();
}	

function change_section(active,back=false)
{
	const content=document.querySelector(".app-content");

	const prev=document.querySelector("section.active");
	const actual=document.getElementById(active);
	actual.classList.add("active");
	if(prev)
	{
		if(!back)
			prev.setAttribute("aria-scroll",content.scrollTop);
		prev.classList.remove("active");
	}

	if(actual.hasAttribute("aria-scroll"))
	{
		content.scrollTop=parseInt(actual.getAttribute("aria-scroll"));
		actual.removeAttribute("aria-scroll");
	}

	if(actual.hasAttribute("aria-focus"))
	{
		setTimeout(function(){
			document.getElementById(actual.getAttribute("aria-focus")).focus();
		},0);
	}
}

function update_test_progress()
{
	const progress=document.getElementById("test_progress");
	const max=parseInt(progress.getAttribute("max"));
	const value=parseInt(progress.getAttribute("value"));
	
	document.querySelector("#test .test_count").innerHTML=`${value}/${max}`;
	progress.querySelector(".custom_progress_value").style.width=value*100/max+"%";
}

function prepare_question(question)
{
	player.current_question=question;
	const test=document.getElementById("test");
	test.classList.remove("correct");
	test.classList.remove("incorrect");
	test.querySelector(".test_footer").classList.remove("ready");
	//test.querySelector(".test_count").innerHTML=`${parseInt(question)+1}/${kivi.questions.length}`;
	
	console.log(player.values.player_library,player.current_test);
	if(player.values.player_library && player.values.player_library[player.current_subject])
	{
		if(check_screenshot(player.values.player_library[player.current_subject],
				player.current_test,question))
			document.getElementById("btn_test_popup_screenshot").classList.add("saved");
		else 
			document.getElementById("btn_test_popup_screenshot").classList.remove("saved");
	}

	document.getElementById("test_progress").setAttribute("value",parseInt(question)+1); 
	update_test_progress();
}
function load_question()
{
	const q=kivi.questions[player.results[player.current_question].question];

	const parent=document.querySelector(`#elements .question_type_${q.question_type_id}`).cloneNode(true);
	const subtype=question_functions[`get_subtype_${q.question_type_id}`](q);
	const content=document.querySelector(`#elements .question_type_${q.question_type_id}_${subtype}`).cloneNode(true);

	const test_id=q.test_id || player.current_test;

	console.log("SUBJECT: ",kivi.units[kivi.apartats[kivi.tests[test_id].apartat_id].unit_id].subject_id);
	question_functions[`setup_test_${q.question_type_id}`](content,q);

	content.querySelectorAll("[aria-field]").forEach(el=>{
		const field=el.getAttribute("aria-field");
		if(q[field])
		{
			if(el instanceof Image)
			{
				const subject=kivi.units[kivi.apartats[kivi.tests[test_id].apartat_id].unit_id].subject_id;
				el.setAttribute("src",
				`${image_path+subject}/${subject}_CONTENT/${q[field]}.png`);
			}else{
				const val=el.querySelector("[aria-value]")
				if(val) el=val;
				el.innerHTML=q[field];
			}
		}else{
			console.log("Column Error: "+field);
		}
	});

	parent.querySelector(".question_text").innerHTML=q.question;
	parent.querySelector(".question_content").append(content);	

	document.getElementById("test_content").innerHTML="";
	document.getElementById("test_content").append(parent);	

	if(question_functions[`start_test_${q.question_type_id}`])
		question_functions[`start_test_${q.question_type_id}`](content);

}

function shuffle_array(array) 
{
	const shuffledArray = [...array]; // Crear una copia del array original
	for (let i = shuffledArray.length - 1; i > 0; i--) {
	  const j = Math.floor(Math.random() * (i + 1));
	  [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
	}
	return shuffledArray;
}

function get_next_question()
{
	//console.log("Results: ",player.results);
	//console.log(player.current_question);
	if(player.current_question+1<player.results.length)
		return({question:player.current_question+1,loop:false});

	for(let a=0;a<player.results.length;a++)
	{
		if(player.results[a].status)
		{
				player.results.splice(a, 1); 
				a--; 
		}
	}

	//console.log("New results: ",player.results);

	player.test_loop++;

	if(player.results.length>0)
		return({question:0,loop:true});

	return(null);
}

async function start_test(test_id,question=0,random=false,custom_name=null)
{
	const res=await server_function("get_questions",{test_id:test_id});
	if(!res)
	{
		show_alert("Error cargando preguntas");
		return;
	}
	kivi.questions=res.questions;

	console.log("Start test: "+test_id,kivi.questions,random);

	player.current_test=test_id;
	player.test_loop=0;
	player.test_coins=0;
	player.test_correct=0;
	player.test_time=0;
	player.retest=random;
	player.time_interval=setInterval(function()
	{
		player.test_time++;
	},1000)

	const test=document.getElementById("test");
	
	test.querySelector(".test_name").innerHTML=custom_name || kivi.tests[test_id].name;

	document.getElementById("test_progress").setAttribute("max",kivi.questions.length); 

	player.results=[];
	for(let q=0;q<kivi.questions.length;q++)
		player.results.push({question:q,status:false,custom_result:true});

	if(random)
		player.results=shuffle_array(player.results);

	if(custom_name) player.retest=true;

	prepare_question(question);

	const imgs=get_imgs_questions(kivi.questions);
	console.log(imgs);
	change_section("loading");
	await preload_images(imgs,true);

	change_section("test");
	load_question();
	
}

function check_test(e)
{
	e.preventDefault();
	const footer=document.getElementById("test_footer");
	if(footer.classList.contains("ready"))
	{
		const test=document.getElementById("test");
		
		const q=kivi.questions[player.results[player.current_question].question];

		if((test.classList.contains("correct")) || (test.classList.contains("incorrect")))
		{
			// comprobar si ha acabado el test
				
			console.log("Check correct:", test.classList.contains("correct")," result: ",player.results[player.current_question]);
			if(test.classList.contains("correct"))
			{
				if(q.question_type_id!=2)
				{
					if(player.test_loop==0) player.test_coins++;
					player.results[player.current_question].status=true;
				}else{
					if(player.results[player.current_question].custom_result)
					{
						if(player.test_loop==0) player.test_coins++;
						player.results[player.current_question].status=true;
					}				}
			}

			console.log("Test coins: ",player.test_coins);

			const next=get_next_question();
			if(next===null)
			{
				screen_functions["show_finish"](null);
				return;
			}

			if(next.loop)
			{
				play_sounds("again");
				document.getElementById("btn_continue_test").setAttribute("aria-next-question",next.question);
				change_section("restart");

				//let total=0;
				//for(let a=0;a<player.results.length;a++)
				//	if(player.results[a].status==player.test_loop) total++;
				//document.getElementById("test_progress").setAttribute("max",total); 
				document.getElementById("test_progress").setAttribute("max",player.results.length);

				return;
			}
			prepare_question(next.question);
			load_question();
		}else{		
			//const q=kivi.questions[player.current_test][player.results[player.current_question].question];
			// player.questions[player.results[player.current_question].question];
			const res=question_functions[`check_test_${q.question_type_id}`](test.querySelector(".question_content"),q);
			play_sounds(res?"correct":"incorrect");
			test.classList.add(res?"correct":"incorrect");
		}
	}
};
function shuffle_elements(container) {
	const itemsArray = Array.from(container.children);
	for (let i = itemsArray.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[itemsArray[i], itemsArray[j]] = [itemsArray[j], itemsArray[i]];
	}
	container.innerHTML = '';
	itemsArray.forEach(item => container.appendChild(item));
}


async function load()
{
	//init_google_auth();

	show_lng(page_lng)
	
	document.querySelector(".app-content").classList.remove("hide");
	
	const ret=await server_function("get_courses");
	if((!ret) || (ret.status==false))
	{
		show_alert("No se ha podido conectar con el servidor "+server_url);
		return;
	}
	kivi=ret.data;

	//ret.data.subjects["Test"]=ret.data.subjects["2B_BIO_ESP"]; // TEST
	//ret.data.subjects["Test"].subject_id="Test";

	console.log(ret);

	document.getElementById("btn_test_check").addEventListener("click",check_test);

	document.querySelectorAll("[aria-to-section").forEach(el=>{
		el.addEventListener("click",e=>{
			e.preventDefault();
			if(el.hasAttribute("no-reload"))
			{
				change_section(el.getAttribute("href"),true);
			}else{
				if(el.getAttribute("aria-to-section")=="func")
					screen_functions[el.getAttribute("href")](el);
				else
					change_section(el.getAttribute("href"));
			}
		});
	});


	for(let s in sounds)
	{
		sounds[s].el=document.querySelector(`[aria-sound="${s}"]`);
		sounds[s].data=await load_sound(sounds[s].el.getAttribute("src"));
	}

	//change_section("home");

	if(page_vars["test"])
	{
		start_test(page_vars["test"],page_vars["question"]);
		return;
	}

	const inputs = document.querySelectorAll('#register_user_code input');

	inputs.forEach((input, index) => {
    	input.addEventListener('input', (e) => {
	        if (input.value.length === 1 && index < inputs.length - 1) {
    	        inputs[index + 1].focus();
        	}
    	});

    	input.addEventListener('keydown', (e) => {
        	if (e.key === 'Backspace' && index > 0 && input.value === '') {
            	inputs[index - 1].focus();
        	}
    	});

		input.addEventListener('paste', (e) => {
			e.preventDefault();
			const paste = e.clipboardData.getData('text');
			const digits = paste.split('');
			for (let i = 0; i < inputs.length; i++) {
				if (digits[i]) {
					inputs[i].value = digits[i];
				}
			}
			inputs[inputs.length - 1].focus();
			//screen_functions["authenticate"](e.target);  
		});
	});

	document.getElementById("library_image").addEventListener("click",e=>{
		e.preventDefault();
		document.getElementById("library_popup").classList.add("show");			
	});

	//change_section("start_code");
	//return;

	screen_functions["start_register"]();

	//show_alert("¿Seguro que quieres salir del test y perder el avance?",false,true,"do_cancel_test","close_question_popup");
}

const screen_functions={

	share_screenshot:async function(el)
	{
		if (navigator.share) 
		{
			const img = document.getElementById("library_image");
			const response = await fetch(img.src,{mode: 'cors'});
			const blob = await response.blob();
			const file = new File([blob], 'kivi_screenshot.jpg', { type: blob.type });
			if(navigator.canShare({ files: [file] }))
			{
				await navigator.share({
					files: [file], 
					title: 'Compartir Imagen',
					text: 'Echa un vistazo a esta imagen'
				});

			}else{
				screen_functions["close_library_popup"](el);
				show_alert('No se pueden compartir imágenes en este dispositivo.');
			}		
		} else {
			screen_functions["close_library_popup"](el);
			show_alert('No se puede compartir en este dispositivo.');
		}
	},
	confirm_delete_screenshot:function(el)
	{
		document.getElementById("library_popup").classList.remove("show");
		setTimeout(function()
		{
			show_alert("¿Estás seguro que quieres eliminar esta captura?",false,true,
				"delete_screenshot","close_library_popup");
		}, 500);
	},
	delete_screenshot:async function(el)
	{
		const item=document.querySelector(".library_item.selected");
		screen_functions["close_library_popup"](el);
		const ret=await server_function("remove_screenshot", {screenshot:parseInt(item.getAttribute("aria-id"))});
		if((!ret) || (ret.status==false))
		{
			show_alert("No se ha podido eliminar la captura");
		}else{
			if(item.parentElement.children.length==1)
				item.parentElement.parentElement.remove();
			else{
				item.parentElement.parentElement.querySelector(".library_subject_items").innerHTML=item.parentElement.children.length-1;
				item.remove();
			}
		}
	},
	show_screenshot:function(el)
	{
		document.getElementById("library_image").setAttribute("src",el.querySelector("img").getAttribute("src"));
		document.getElementById("library_image").classList.add("show");
		//document.getElementById("library_popup").classList.add("show");
		const prev=document.querySelector(".library_item.selected");
		if(prev)
			prev.classList.remove("selected");
		el.classList.add("selected");
	},
	close_library_popup(el)
	{
		document.getElementById("library_image").classList.remove("show");
		document.getElementById("library_popup").classList.remove("show");
		screen_functions["close_question_popup"](el);
	},
	share_question:function(el)
	{
		if (navigator.share) 
		{
			const captureElement = document.getElementById('test');
			html2canvas(captureElement,{useCORS:true}).then(canvas => {
				canvas.toBlob(blob => {
					const archivo = new File([blob], "kivi_screenshot.jpg", { type: 'image/jpeg' });
		
					// Verificar si la API Web Share soporta compartir archivos
					if (navigator.canShare({ files: [archivo] })) {
						navigator.share({
							title: 'Captura de pantalla',
							text: 'Mira esta captura de pantalla',
							files: [archivo],
						})
						.then(() => console.log('Imagen compartida exitosamente')) 
						.catch((error) => {
							//screen_functions["close_test_popup"](el);
							//show_alert('Problema al compartir el resutlado.');
						});
					} else {
						screen_functions["close_test_popup"](el);
						show_alert('No se pueden compartir imágenes en este dispositivo.');
					}
				}, 'image/jpeg',0.95);
			});
	
		} else {
			screen_functions["close_test_popup"](el);
			show_alert('No se puede compartir en este dispositivo.');
		}
	},
	capture_question:async function(el)
	{
		const ret=await server_function("check_screenshot",
			{ subject:player.current_subject, test:player.current_test, question:player.current_question},null,true);
		if((!ret) || (ret.status==false))
		{
			show_alert("No se ha podido comprobar si exista ya la captura");
			return;
		}else{
			set_values(ret.values);
			if(ret.exists)
			{
				show_alert("Ya existe una captura de esta pregunta.");
				screen_functions["close_test_popup"](null);
				return;
			}
		}

		const captureElement = document.getElementById('test');
            
		html2canvas(captureElement,{useCORS:true}).then(canvas => {
		//domtoimage.toPng(captureElement).then(function (dataUrl) {
			const capture=document.getElementById("capture_picture");
			capture.querySelector(".capture_image").setAttribute("src",
						//dataUrl
						canvas.toDataURL('image/png')
						);
			capture.classList.add("show");
			setTimeout(function()
			{
				capture.classList.add("reveal");
				screen_functions["close_test_popup"](null);
			},100);
			setTimeout(function()
			{
				capture.classList.add("close");
				canvas.toBlob(async (blob) => {
					const formData = new FormData();
					formData.append('subject', player.current_subject);
					formData.append('test', player.current_test);
					formData.append('question', player.current_question);
					formData.append('screenshot', blob, 'screenshot.jpg');			

					const ret=await server_function("add_screenshot",null,null,true,formData);
					if((!ret) || (ret.status==false))
					{
						show_alert("No se ha podido añadir la captura");
					}else{
						set_values(ret.values);
					}
				}, 'image/jpeg',0.95);
				
				setTimeout(function()
				{
					capture.classList.remove("show");
					capture.classList.remove("reveal");
					capture.classList.remove("close");
				},1000);
			},2000);
			/*const link = document.createElement('a');
			link.href = canvas.toDataURL('image/png');
			link.download = 'captura.png';
			link.click();
			*/
		});
	},
	close_test_popup:function(el)
	{
		document.querySelector(".alert_background").classList.add("hide");
		document.getElementById("test_popup").classList.remove("show");
		//document.getElementById("test_footer").classList.remove("hide");
	},
	show_test_popup:function(el)
	{
		document.querySelector(".alert_background").classList.remove("hide");
		document.getElementById("test_popup").classList.add("show");
		//document.getElementById("test_footer").classList.add("hide");
	},
	show_progress:async function(el)
	{
		const ret=await server_function("get_user_stats",{});
		if((!ret) || (ret.status==false))
		{
			show_alert("No se ha podido acceder al progreso");
		}
		console.log("get_user_stats",ret);

		//ret.stats.points["Test"]=ret.stats.points["2B_EMP_ESP"]; // TEST

		const wrapper=document.getElementById("progress_time_value").parentElement;
		const t=format_time(ret.stats.time);
		animate_counter(document.getElementById("progress_time_value"),0,t.value,500);
		//document.getElementById("progress_time_value").innerHTML=t.value;

		wrapper.querySelectorAll("[aria-unit]").forEach((el)=>el.classList.add("hide"));
		wrapper.querySelector(`[aria-unit="${t.unit}"]`).classList.remove("hide");

		const needle = document.getElementById('progress_needle');
		needle.style.transform = `rotate(0deg)`;
		setTimeout(function()
	    {
			const angle = (ret.stats.position * 180) / 100 - 90;
    	    needle.style.transform = `rotate(${angle}deg)`;
		},500);

		const parent=document.getElementById("points_grid");
		parent.innerHTML="";
		for(let p in ret.stats.points)
		{
			const k=ret.stats.points[p];

			console.log(k);

			const item=document.querySelector(`#elements .points_item`).cloneNode(true);

			const progress_circle = item.querySelector('.progress_circle');
        	const perimeter = 283;
			progress_circle.style.strokeDasharray = `${perimeter - (perimeter - (0 / 100) * perimeter)}, ${perimeter}`;
			setTimeout(function(){
        		const offset = perimeter - (k.done / 100) * perimeter;
				progress_circle.style.strokeDasharray = `${perimeter - offset}, ${perimeter}`;
			},500);
		
        	
			item.querySelector(".points_answers").innerHTML=k.answers;
			item.querySelector(".points_gauge_value").innerHTML=k.done;
			let icon="low";
			if(k.answers>=50)
			{
				if(k.answers>=80)
					icon="high";
				else
					icon="mid";
			}
			item.querySelector(`.points_result [aria-data='${icon}']`).classList.remove("hide");
			item.querySelector(".points_title").innerHTML=k.title;

			parent.append(item);
		}

		change_section("progress");
	},
	show_profile_extras:function(el)
	{
		document.querySelector(".alert_background").classList.remove("hide");
		document.getElementById("profile_popup").classList.add("show");
	},
	do_delete_account:async function(el)
	{
		const ret=await server_function("delete_account",{});
		if((!ret) || (ret.status==false))
		{
			show_alert("No se ha podido eliminar la cuenta, porfavor envíanos un email a: info@kivi.app");
			return;
		}
	},

	confirm_delete_account:async function(el)
	{
		screen_functions["close_question_popup"](el);
		setTimeout(function()
		{
			show_alert("Esto eliminará todos tus progresos. ¿Estás seguro?",false,true,
				"do_delete_account","close_question_popup");
		}, 500);
	},

	delete_account:async function(el)
	{
		show_alert("¿Seguro que quieres eliminar tu cuenta?",false,true,
		"confirm_delete_account","close_question_popup");
	},
	update_profile:async function(el)
	{
		const ret=await server_function("update_profile",
						{username:document.getElementById("profile_username").value,
						avatar:document.getElementById("profile_avatar").getAttribute("aria-value")});
		
		if((!ret) || (ret.status==false))
		{
			show_alert("Ha ocurrido un error");
			return;
		}

		show_alert("Perfil actualizado",false);
	},
	buy_avatar:function(el)
	{
		player.last_purchase=el.parentElement.parentElement.getAttribute("aria-id");
		show_alert("¿Seguro que quieres comprar el icono?",false,true,"do_buy_avatar","close_question_popup");
	},
	do_buy_avatar:async function(el)
	{
		const ret=await server_function("buy_product",{product:player.last_purchase});
		
		if((!ret) || (ret.status==false))
		{
			if(ret.error==501)
				show_alert("No tienes suficientes monedas");
			else
				show_alert("No se ha podido realizar la compra");
			return;
		}

		show_alert("Compra realizada",false);

		const item=document.querySelector(`.avatar_item[aria-id='${player.last_purchase}']`);
		item.querySelector(".btn_buy_avatar").classList.add("hide");
		item.setAttribute("selectable","");		
		set_values(ret.values);

	},
	change_avatar:function(el)
	{
		const item=el.parentElement.parentElement;
		if(!item.hasAttribute("selectable")) return;
		
		const target=document.getElementById("profile_avatar");
		target.src=el.querySelector("img").src;
		target.setAttribute("aria-value",item.getAttribute("aria-id"));
		
		const prev=document.querySelector(".avatar_item.selected");
		if(prev) prev.classList.remove("selected");

		item.classList.add("selected");

		change_section("profile");
	},
	continue_test:function(el)
	{
		prepare_question(parseInt(el.getAttribute("aria-next-question")));
		load_question();
		change_section("test");
	},

	auth_apple:async function(el)
	{
		try{
			const response = await AppleID.auth.signIn();
		}catch(e){}
	},
	auth_social:async function(el)
	{
		//window.location.href=server_url+"auth/"+el.getAttribute("aria-target");
		//return;

		const ret=await server_function("auth/"+el.getAttribute("aria-target"));
		if(ret && ret.status)
		{
			alert(ret.url);
			window.open(ret.url);
		}else 
			show_alert("Error de autentificación");
		
	//	window.location.href=server_url+"auth/"+el.getAttribute("aria-target");
	},


	start_register:async function()
	{
		let select=document.getElementById("register_user_day");
		for(let a=1;a<=31;a++)
		{
			const op=document.createElement("option");
        	op.value=a;
            op.text=a;
			select.append(op);
		}
		select=document.getElementById("register_user_year");
		for(let a=2020;a>=1960;a--)
		{
			const op=document.createElement("option");
        	op.value=a;
            op.text=a;
			select.append(op);
		}

		document.querySelectorAll(".register_options .register_option").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				const prev=el.parentNode.querySelector(".register_option.selected");
				if(prev) prev.classList.remove("selected");
				el.classList.add("selected");
				setTimeout(function(){
					screen_functions[el.parentNode.getAttribute("aria-new-section")](null);
				},500);
			});
		});

		const ret=await server_function("login/check_session");
		//console.log(ret);
		if((!ret) || (ret.status))
		{
			console.log(ret.user);
			set_values(ret.values);
			document.getElementById("profile_email").value=ret.user.user_email;
			if(ret.user.user_status==0)
				change_section("register_start");
			else
				screen_functions["show_subjects"](this);
			return;
		}
		if(!auth_init_done)
		{
			init_google_auth();
			init_apple_auth();
			auth_init_done=true;
		}
		change_section("start_email");
	},
	check_code:async function(el)
	{
		const email=document.getElementById("register_user_email");
		if(!email.checkValidity())
		{
			show_alert("Email no válido");
			return;
		}
		const ret=await server_function("login/magic_code",{email:email.value});
		if((!ret) || (ret.status==false))
		{
			show_alert("Error authentication");
			return;
		}
		document.getElementById("profile_email").value=ret.user.user_email;
		localStorage.kivi_session=ret.token;

		change_section("start_code");
	},
	authenticate:async function(el)
	{
		const email=document.getElementById("register_user_email");

		let code="";
		document.querySelectorAll('#register_user_code input').forEach((input) => {
			code+=input.value;
		});
		
		if(code.trim()!="")
		{
			const ret=await server_function("login/authenticate",{email:email.value,code:code});
			if((!ret) || (ret.status==false))
			{
				if(ret.error==105)
					show_alert("Código no válido");
				else
					show_alert("Error");
				return;
			}
			localStorage.kivi_session=ret.token;

			set_values(ret.values);

			if(ret.user_unregistered)
				change_section("register_start");
			else
				screen_functions["show_subjects"](this);	
		}
	},
	close_session:async function(el)
	{
		show_alert("¿Seguro que quieres cerrar sesión?",false,true,
		"do_close_session","close_question_popup",true);
	},
	do_close_session:async function(el)
	{
		const ret=await server_function("login/close_session");
		if((!ret) || (ret.status==false))
		{
			show_alert("Error al cerrar sesión");
			return;
		}
		screen_functions["close_question_popup"](el);

		localStorage.removeItem("kivi_session");

		Object.keys(results).forEach(key => delete results[key]);
		Object.keys(apartat_progress).forEach(key => delete apartat_progress[key]);

		document.getElementById("register_user_email").value="";
		
		document.querySelectorAll('#register_user_code input').forEach((input) => {
			input.value="";
		});

		change_section("start_email");
	},
	register_state:function(el)
	{
		const day = parseInt(document.getElementById('register_user_day').value, 10);
		const month = parseInt(document.getElementById('register_user_month').value, 10);
		const year = parseInt(document.getElementById('register_user_year').value, 10);
	
		const date = new Date(year,month-1,day);
	
		if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day)
		{
			show_alert("Fecha no válida");
			return;
		}
		change_section("register_state");
	},
	register_know:function(el)
	{		
		change_section("register_know");
	},
	/*register_confirm:function(el)
	{
		const confirm=document.getElementById("register_confirm");
		confirm.querySelector(".value_birthday").innerHTML=document.getElementById("register_user_day").value+"/"+
		document.getElementById("register_user_month").value+"/"+document.getElementById("register_user_year").value;
		confirm.querySelector(".value_state").innerHTML=document.querySelector(".register_state .register_option.selected .option_text").innerHTML;
		confirm.querySelector(".value_know").innerHTML=document.querySelector(".register_know .register_option.selected .option_text").innerHTML;;
		
		change_section("register_confirm");
	},*/
	register_user:async function(el)
	{
		const ret=await server_function("login/complete_register",
		{state:document.querySelector(".register_state .register_option.selected").getAttribute("aria-value"),
		know:document.querySelector(".register_know .register_option.selected").getAttribute("aria-value"),
		birthday:document.getElementById('register_user_year').value+"-"+
				document.getElementById('register_user_month').value+"-"+
				document.getElementById('register_user_day').value
		});
		if((!ret) || (ret.status==false))
		{
			show_alert("Error");
			return;
		}
		set_values(ret.values);

		change_section("register_end");
	},
	show_subjects:async function(el)
	{
		const course="2B";
		console.log(kivi);
		
		document.querySelector("#subjects .top_header_title").innerHTML=kivi.courses[course].name;
		const subjects_grid=document.querySelector("#subjects .main_grid");
		const clone_subject=document.querySelector("#elements .subject_item");
		const clone_unit=document.querySelector("#elements .subject_unit_item");

		for(let s in kivi.subjects)
		{
			if(kivi.subjects[s].course_id==course)
			{
				const subject=clone_subject.cloneNode(true);
				subject.querySelector(".grid_title").innerHTML=kivi.subjects[s].name;
				const units=subject.querySelector(".grid_items");
				for(let u in kivi.units)
				{
					if(kivi.units[u].subject_id==s)
					{
						const unit=clone_unit.cloneNode(true);

						const path=`${image_path+kivi.units[u].subject_id}/${kivi.units[u].subject_id}_INDEX/${kivi.units[u].image_id}.png`;
						//await preload_image(path);

						unit.querySelector(".item_img").src=path;
						
						unit.querySelector(".item_title").innerHTML=kivi.units[u].name.replace(".",".<br>");
						unit.addEventListener("click",e=>{
							e.preventDefault();
							screen_functions.show_units(u,s);
						});
						units.append(unit);
					}else{
						if(units.children.length) break;
					}
				}
				subject.querySelector(".grid_header").addEventListener("click",e=>{
					e.preventDefault();
					subject.classList.toggle("open");
				})
				subjects_grid.append(subject);
			}else{
				if(subjects_grid.children.length) break;
			}
		}
		
		change_section("subjects");

	},
	show_units:async function(unit_id,subject)
	{
		document.getElementById("popup_coins").classList.remove("show");
		document.getElementById("popup_streak").classList.remove("show");

		player.current_subject=subject;

		if(!results[subject])
		{		

			const res=await server_function("get_results",{subject:subject});
			if((!res) || (res.state=false))
			{
				show_alert("error");
				return;
			}
			results[subject]=res.results;
			apartat_progress[subject]=res.progress;
			console.log(res);
		}
		document.querySelector("#units .top_header_title").innerHTML=kivi.subjects[subject].name;

		const units_grid=document.querySelector("#units .main_grid");
		const clone_unit=document.querySelector("#elements .unit_item");
		const clone_section=document.querySelector("#elements .unit_section_item");

		units_grid.innerHTML="";

		for(let s in kivi.units)
		{
			if(kivi.units[s].subject_id==subject)
			{
				const unit=clone_unit.cloneNode(true);
				unit.querySelector(".grid_header_img").setAttribute("src",
					`${image_path+subject}/${subject}_INDEX/${kivi.units[s].image_id}.png`);
				unit.querySelector(".grid_title").innerHTML=kivi.units[s].name;
				unit.setAttribute("id",s);
				if(s==unit_id)
					unit.classList.add("open");
				const units=unit.querySelector(".grid_items");
				for(let u in kivi.apartats)
				{
					if(kivi.apartats[u].unit_id==s)
					{
						const section=clone_section.cloneNode(true);
						section.setAttribute("id","item_"+u);
						const path=`${image_path+subject}/${subject}_INDEX/${kivi.apartats[u].image_id}.png`;
						//await preload_image(path);
						section.querySelector(".item_img").src=path;
						
						if(apartat_progress[subject])
						{		
							if(apartat_progress[subject][u])
							{
								section.querySelector(".item_progress_bar").style.width=apartat_progress[subject][u].completion_percentage+"%";
								section.querySelector(".item_progress_text").innerHTML=apartat_progress[subject][u].completion_percentage+"%";
							}
						}

						section.querySelector(".item_title").innerHTML=kivi.apartats[u].name.replace(".",".<br>");
						section.addEventListener("click",e=>{
							e.preventDefault();
							screen_functions.show_sections(subject,u,s);
						});
						units.append(section);
					}else{
						if(units.children.length) break;
					}
				}
				unit.querySelector(".grid_header").addEventListener("click",e=>{
					e.preventDefault();
					unit.classList.toggle("open");
				});
				units_grid.append(unit);
				
			}else{
				if(units_grid.children.length) break;
			}
		}
		
		change_section("units");

		document.getElementById(unit_id).scrollIntoView();

	},
	show_sections:async function(subject_id,section_id,unit_id)
	{		
		document.querySelector("#sections .top_header_title").innerHTML=kivi.units[unit_id].name;

		const sections_grid=document.querySelector("#sections .main_grid");
		const clone_section=document.querySelector("#elements .section_item");
		const clone_test=document.querySelector("#elements .section_test_item");

		sections_grid.innerHTML="";

		for(let s in kivi.apartats)
		{
			if(kivi.apartats[s].unit_id==unit_id)
			{
				const section=clone_section.cloneNode(true);
				section.querySelector(".grid_title").innerHTML=kivi.apartats[s].name;
				section.querySelector(".grid_time_data").innerHTML=kivi.duration[s];
				section.setAttribute("id",s);
				if(s==unit_id)
					section.classList.add("open");
				const tests=section.querySelector(".grid_items");
				for(let u in kivi.tests)
				{
					if(kivi.tests[u].apartat_id==s)
					{
						const test=clone_test.cloneNode(true);
						test.setAttribute("id",u);
						if(results[subject_id])
						{
							if(results[subject_id][u])
								test.classList.add("done");
						}

						const path=`${image_path+subject_id}/${subject_id}_INDEX/${kivi.tests[u].image_id}.png`;
						//await preload_image(path);
						test.querySelector(".item_img").src=path;
						
						test.querySelector(".item_title").innerHTML=kivi.tests[u].name;
						test.addEventListener("click",e=>{
							e.preventDefault();
							screen_functions.show_start(u,s);
						});
						tests.append(test);
					}else{
						if(tests.children.length) break;
					}
				}

				// test plus
				const test=clone_test.cloneNode(true);
				const test_id="_"+s;
				const test_name="Repaso apartado"
				test.setAttribute("aria-custom-name",test_name);
				test.setAttribute("id",test_id);
				test.querySelector(".item_title").innerHTML=`<b>${test_name}</b>`;
				const test_img=document.getElementById("image_repaso").getAttribute("src"); //${image_path+subject_id}/${subject_id}_INDEX/${kivi.apartats[s].image_id}.png`;		
				test.querySelector(".item_img").src=test_img;
				
				test.addEventListener("click",e=>{
					e.preventDefault();
					screen_functions.show_start(test_id,s,test_name,test_img,test_img)
				});
				tests.append(test);

				sections_grid.append(section);
				
			}else{
				if(sections_grid.children.length) break;
			}
		}
		
		change_section("sections");

		const height=document.querySelector("#sections .top_header").clientHeight;
	
		const main_grid=document.querySelector("#sections .main_grid");
		main_grid.style["padding-top"]=height+"px";
		console.log(section_id);
		document.getElementById(section_id).style["scroll-margin-top"]=height+20+"px";	
		document.getElementById(section_id).scrollIntoView();
	},
	show_start:function(test_id,section_id,test_name=null,test_img=null)
	{		
		player.current_apartat=section_id;
		player.current_unit=kivi.apartats[section_id].unit_id;
		player.current_test=test_id;

		//alert("Apartat: "+player.current_apartat);
		//alert("Unit: "+player.current_unit);
		
		const unit_id=kivi.apartats[section_id].unit_id;
		console.log(unit_id);
		console.log(kivi.units[unit_id].subject_id);

		const start=document.getElementById("start");
		if(!test_name)
		{
			start.querySelector(".start_test_name").innerHTML=kivi.tests[test_id].name;
			start.querySelector(".start_test_img").setAttribute("src",
		`	${image_path+kivi.units[kivi.apartats[section_id].unit_id].subject_id}/${kivi.units[kivi.apartats[section_id].unit_id].subject_id}_INDEX/${kivi.tests[test_id].image_id}.png`);
		}else{
			start.querySelector(".start_test_name").innerHTML=test_name;
			start.querySelector(".start_test_img").setAttribute("src",test_img);
		}
		start.querySelector(".top_header_title").innerHTML=kivi.apartats[section_id].name;

		const btn_start=document.getElementById("btn_start_test");
		const btn_restart=document.getElementById("btn_restart_test");
		btn_start.setAttribute("aria-test",test_id);
		if(test_name)
			btn_start.setAttribute("aria-custom-name",test_name);
		else 
			btn_start.removeAttribute("aria-custom-name");
		
		btn_restart.setAttribute("aria-test",test_id);

		if((results[player.current_subject]) && (results[player.current_subject][test_id]))
		{		
			btn_restart.classList.remove("hide");		
		}else{
			btn_restart.classList.add("hide");			
		}
		change_section("start");
	},
	show_test:function(el)
	{
		start_test(el.getAttribute("aria-test"),0,el.hasAttribute("aria-random"),
			el.getAttribute("aria-custom-name"));
	},
	cancel_test:function(el)
	{
		show_alert("¿Seguro que quieres salir del test y perder los avances?",false,true,
			"do_cancel_test","close_question_popup");

	},
	do_cancel_test:function(el)
	{
		clearInterval(player.time_interval);
		change_section("sections");
		screen_functions["close_question_popup"](el);
	},
	show_finish:async function(el)
	{
		play_sounds("done");

		player.test_correct=player.test_coins;
		
		if(player.retest)
			player.test_coins=35;
		else{
			if(player.test_coins)
			{
				player.test_coins=parseInt((player.test_coins/kivi.questions.length)*10);
			}
		}
		document.getElementById("test_reward_coins").innerHTML=player.test_coins;

		const prev=document.querySelector(".finish_img img.active");
		if(prev) prev.classList.remove("active");
		const imgs=document.querySelectorAll(".finish_img img");
		imgs[Math.floor(Math.random() * imgs.length)].classList.add("active");

		change_section("finish");

		clearInterval(player.time_interval);

		const ret=await server_function("add_result",{
			coins:player.test_coins,
			correct:player.test_correct,
			questions:kivi.questions.length,
			subject:player.current_subject,
			apartat:player.current_apartat,
			unit:player.current_unit,
			test:player.current_test,
			time:player.test_time
		}); 
		if((!ret) || (ret.status==false))
		{
			show_alert("Error");
			return;
		}
		set_values(ret.values);

		if(ret.update_result)
		{
			document.getElementById(player.current_test).classList.add("done");
			const apartat=document.getElementById("item_"+player.current_apartat);
			if(ret.progress[player.current_apartat])
			{
				apartat.querySelector(".item_progress_bar").style.width=ret.progress[player.current_apartat].completion_percentage+"%";
				apartat.querySelector(".item_progress_text").innerHTML=ret.progress[player.current_apartat].completion_percentage+"%";	
			}
			results[player.current_subject]=ret.results;
			apartat_progress[player.current_subject]=ret.progress;
		}
		set_ranking(ret.ranking);
	},
	

	show_ads:async function(el)
	{
		if(el.hasAttribute("aria-coins"))
		{
			const ret=await server_function("ads_done",{coins:parseInt(el.getAttribute("aria-coins"))}); 
			if((!ret) || (ret.status==false))
			{
				show_alert("Error");
				return;
			}
			set_values(ret.values);
	
		}else{
			if(!isCordova)
			{
				screen_functions["finish_ads_test"](el);
				return;
			}
		}
		document.getElementById("popup_coins").classList.remove("show");
		document.getElementById("popup_streak").classList.remove("show");

		document.getElementById("btn_continue_ads").setAttribute("href",el.getAttribute("aria-href"));

		change_section("ads");
	},

	finish_ads_test:function(el)
	{
		const keys = Object.keys(kivi.tests);
		const index = keys.indexOf(player.current_test);

		const next=document.getElementById("btn_next_test");
		if((index !== -1 && index < keys.length - 1) && 
			(kivi.apartats[kivi.tests[player.current_test].apartat_id].unit_id == 
				kivi.apartats[kivi.tests[keys[index+1]].apartat_id].unit_id))
		{
    		const next_key = keys[index + 1];
			next.classList.remove("hide");
			next.setAttribute("aria-next-test",next_key);
		}else{
			next.classList.add("hide");
		}
		change_section("ranking");
	},

	finish_ads_coins:function(el)
	{
		change_section(el.getAttribute("aria-post"));	// TODO; actualizar estados llamando a la opción que muestra los test?
	},

	next_test:function(el)
	{
		const next=document.getElementById("btn_next_test").getAttribute("aria-next-test");
		screen_functions["show_start"](next,kivi.tests[next].apartat_id);
	},

	popup_coins:function(el)
	{
		if(!isCordova)
			document.getElementById("btn_ads_coins").classList.add("hide");

		document.getElementById("btn_continue_ads").setAttribute("aria-post",el.getAttribute("aria-post"));
		const coins=Math.floor(Math.random() * 15) + 1;
		document.getElementById("ads_coins").innerHTML=coins;
		document.getElementById("btn_ads_coins").setAttribute("aria-coins",coins);
		document.getElementById("popup_streak").classList.remove("show");
		document.getElementById("popup_coins").classList.add("show");
	},

	popup_streak:function(el)
	{
		console.log(player.values);
		const week=document.querySelector(".streak_week");
		for(let a=0;a<7;a++)
		{
			if(player.values.player_week[a]>0)
				week.children[a].classList.add("done");
			else
				week.children[a].classList.remove("done");
		}
		document.getElementById("popup_coins").classList.remove("show");
		document.getElementById("popup_streak").classList.add("show");
	},
	
	close_popup:function(el)
	{
		document.querySelector(".alert_background").classList.add("hide");
		el.parentElement.classList.remove("show");
	},
	close_question_popup:function(el)
	{
		document.querySelector(".alert_background").classList.add("hide");
		document.getElementById("alert_popup").classList.remove("show");
	}
}
	
const question_functions={

	get_subtype_1:function(q)
	{
		if(q.question_subtype_id==2) return(1);
		if(q.question_subtype_id<7) return(2);
		if(q.question_subtype_id==7) return(3);
		return(4);
	},
	setup_test_1:function(content,q)
	{
		shuffle_elements(content.querySelector(".answers"));

		content.querySelectorAll(".btn_answer").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				const test=document.getElementById("test");
				if((test.classList.contains("correct")) || (test.classList.contains("incorrect"))) return;

				play_sounds("select");

				el.classList.toggle("selected");
				if(content.querySelectorAll(".btn_answer.selected").length)
					document.getElementById("test_footer").classList.add("ready");
				else
					document.getElementById("test_footer").classList.remove("ready");
			})
		});
	},
	check_test_1:function(content,q)
	{
		const correct=q.correct_answer.split(",");
		const answers=content.querySelectorAll(`[aria-field]`);

		let result=true;
		answers.forEach(el=>{
			const res=el.getAttribute("aria-field");
			if(correct.includes(res))
			{
				el.classList.add("correct");
				if(!el.classList.contains("selected"))
					result=false;
			}else{
				if(el.classList.contains("selected"))
					result=false;
			}
			el.classList.remove("selected");
		});
		
		return(result);
	},


	get_subtype_5:function(q)
	{
		return(q.question_subtype_id==29?1:2);
	},
	setup_test_5:function(content,q)
	{
		if((q.extra_text) && (q.extra_text!=""))
		{
			q.extra_text=q.extra_text.replace("____",
				"<div class='mistery_word'>"+
				"<span class='word'></span></div>");
		}
		content.querySelector("input").addEventListener("keyup",e=>{
			if(q.extra_text)
				content.querySelector(".mistery_word").innerHTML=e.target.value;
			if(e.target.value.length)
			{
				if(e.key=="Enter")
				{
					document.getElementById("btn_test_check").click();
					return;
				}
				document.getElementById("test_footer").classList.add("ready");
			}else
				document.getElementById("test_footer").classList.remove("ready");
		});
	},
	start_test_5:function(content)
	{
		//content.querySelector("input").focus();
	},
	check_test_5:function(content,q)
	{
		const ret=normalize_text(content.querySelector("input").value)===normalize_text(q.correct_answer);
		if(content.querySelector(".mistery_word"))
			content.querySelector(".mistery_word").innerHTML=q.correct_answer;
		else 
			content.querySelector("input").value=q.correct_answer;
		return(ret);
	},

	get_subtype_3:function(q)
	{
		return(q.question_subtype_id-18);
	},
	setup_test_3:function(content,q)
	{
		shuffle_elements(content.querySelector(".answers_row"));

		content.querySelectorAll(".answers_row .answer").forEach(el=>{
			el.setAttribute("id","id_"+el.getAttribute("aria-field"));
		});

		document.addEventListener('mousemove', handle_mousemove);
		document.addEventListener('mouseup', handle_mouseup);

		content.querySelectorAll(".answer").forEach(el=>{
			el.addEventListener("mousedown",handle_mousedown);
			el.addEventListener("touchstart",handle_mousedown);

			//el.addEventListener("mouseup",handle_mouseup);
			el.addEventListener("touchend",handle_mouseup);
				
			//el.addEventListener("mousemove",handle_mousemove);
			el.addEventListener("touchmove",handle_mousemove);
		});

		content.querySelectorAll(".order_result").forEach(el=>{
			
			el.addEventListener("dragover",e=>{
				e.preventDefault();
				console.log("over");
			});
			el.addEventListener("drop",e=>{
				//e.preventDefault();
				const id=e.dataTransfer.getData('text');
				const draggable=document.getElementById(id);
				el.appendChild(draggable);
				if(content.querySelector(".answers_row .answer")==null)
					document.getElementById("test_footer").classList.add("ready");
			
				e.dataTransfer.clearData();
			});
		});

		
	},
	check_test_3:function(content,q)
	{
		document.removeEventListener('mousemove', handle_mousemove);
		document.removeEventListener('mouseup', handle_mouseup);

		const temp=q.correct_answer.split(";");
		const cols={"v1":temp[0].split(":")[1].split(","),
				 "v2":temp[1].split(":")[1].split(",")};
		for(const v in cols)
		{
			let answers=content.querySelector("."+v).children;
			console.log(answers.length);
			for(let item of answers)
			{
				if(cols[v].includes(item.getAttribute("aria-field")))
				{
					item.classList.add("correct");
				}else{
					item.classList.add("incorrect");
				}
			}
		}

		return(content.querySelector(".incorrect")?false:true);
	},

	get_subtype_4:function(q)
	{
		return(parseInt(q.question_subtype_id)-20);
	},
	setup_test_4:function(content,q)
	{
		const container=content.querySelector(".sort_col_answers");
		let placeholder=null;
		let draggingElement=null;
		//let originalNextSibling=null;
		
		shuffle_elements(container);

		document.getElementById("test_footer").classList.add("ready");
		
		container.addEventListener('mousedown', e=>{
			e.preventDefault();
			if((document.getElementById("test").classList.contains("correct")) ||
				(document.getElementById("test").classList.contains("incorrect")))
				return(null);
			play_sounds("select");

			const data=handle_drag_start(e,container);
			draggingElement=data.draggingElement;
			placeholder=data.placeholder;
			//originalNextSibling = data.draggingElement.nextElementSibling;
		}, {passive: false});

        container.addEventListener('mousemove', (e) => {
            if (draggingElement) {
                e.preventDefault();
				handle_drag_over(e,container,draggingElement,placeholder);
            }
        }, {passive: false});
		
        container.addEventListener('mouseup', e=>{
			handle_drag_end(e,draggingElement,placeholder);
			draggingElement=null;
			placeholder=null;
		});
        // Eventos táctiles
        container.addEventListener('touchstart', e=>{
			e.preventDefault();
			if((document.getElementById("test").classList.contains("correct")) ||
				(document.getElementById("test").classList.contains("incorrect")))
				return(null);
			
			play_sounds("select");
			// TODO: cambio de método por función y globales
			const data=handle_drag_start(e,container);
			draggingElement=data.draggingElement;
			placeholder=data.placeholder;
			//originalNextSibling = data.draggingElement.nextElementSibling;
		}, {passive: false});

        container.addEventListener('touchmove', (e) => {
            if (draggingElement) {
                e.preventDefault();
				handle_drag_over(e,container,draggingElement,placeholder);
            }
        }, {passive: false});
		
        container.addEventListener('touchend', e=>{
			handle_drag_end(e,draggingElement,placeholder);
			draggingElement=null;
			placeholder=null;
		});


	},
	check_test_4:function(content,q)
	{
		//requestAnimationFrame(() => {
		const answers=q.correct_answer.split(",");

		const target=content.querySelector(".sort_col_answers");

		const horizontal=target.hasAttribute("aria-horizontal");
		target.style.position = 'relative';

		const items=target.children;
		
		items[0].parentElement.style.width=items[0].parentElement.offsetWidth+"px";
		items[0].parentElement.style["min-height"]=items[0].parentElement.offsetHeight+"px";
		
		for(let a=0;a<answers.length;a++)
		{
			if(items[a].getAttribute("aria-field").trim()===answers[a]) 
				items[a].classList.add("correct");
			else 
				items[a].classList.add("incorrect");

			const item_rect = items[a].getBoundingClientRect();
			
			items[a].style.width = `${items[a].offsetWidth}px`; //`${item_rect.offsetWidth}px`;
			items[a].style.height = `${items[a].offsetHeight}px`;; //`${item_rect.offsetHeight}px`;
				
			items[a].style.left = `${items[a].offsetLeft}px`; //`${item_rect.left}px`;
			items[a].style.top = `${items[a].offsetTop}px`; //${item_rect.top}px`;

		}
		const gap=20;
		let offset=0; //horizontal ? target.getBoundingClientRect().left : target.getBoundingClientRect().top;

		
		for(let a=0;a<answers.length;a++)
		{
			const item=target.querySelector(`[aria-field="${answers[a]}"]`);
			item.style.position="absolute";
			setTimeout(function()
			{
			if(horizontal)
			{
				item.style.left=offset+"px";
				offset+=item.offsetWidth+gap;
			}else{
				item.style.top=offset+"px";
				offset+=item.offsetHeight+gap;				
			}
		},250);
			
		}
		/*
		setTimeout(function(){
			for(let a=0;a<answers.length;a++)
			{
				items[a].classList.remove("incorrect");
				items[a].classList.add("correct");
			}
		},1000);
		*/
		return(content.querySelector(".sort_col_answers .incorrect")?false:true);
	//});
	},



	get_subtype_2:function(q)
	{
		return(parseInt(q.question_subtype_id)-11);
	},
	setup_test_2:function(content,q)
	{
		//shuffle_elements(content.querySelector(".col_1"));
		shuffle_elements(content.querySelector(".col_2"));

		player.results[player.current_question].custom_result=true;

		document.querySelector(".btn_text_continue").style.display="block";
		document.querySelector(".btn_text_check").style.display="none";
		const qs=q.correct_answer.split(",");

		content.querySelectorAll(".btn_answer").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				if(el.classList.contains("correct")) return;

				const prev=el.parentElement.querySelector(".selected");
				if(prev) prev.classList.remove("selected");

				el.classList.toggle("selected");
				play_sounds("select");

				const a1=content.querySelector(".col_1 .selected");
				const a2=content.querySelector(".col_2 .selected");
				if((a1) && (a2))
				{
					a1.classList.remove("selected");
					a2.classList.remove("selected");	
					if(qs.includes(a1.getAttribute("aria-field")+"-"+a2.getAttribute("aria-field")))
					{
						a1.classList.add("correct");
						a2.classList.add("correct");
						play_sounds("correct");

						if(!content.querySelector(".col_1 .btn_answer:not(.correct"))
						{
							document.getElementById("test").classList.add("correct");
							const footer=document.getElementById("test_footer");
							footer.classList.add("ready");
							document.querySelector(".btn_text_continue").style.display="";
							document.querySelector(".btn_text_check").style.display="";
						}
					}else{
						play_sounds("incorrect");
						player.results[player.current_question].custom_result=false;
					}
				}
			})
		});

	},
	check_test_2:function(content,q)
	{
		return(player.results[player.current_question].custom_result);
	}
};
/*
let dragged_item=null;
function handle_mousedown(e)
{
	e.preventDefault();
	
	el=e.target;

	const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;

	if((document.getElementById("test").classList.contains("correct")) ||
		(document.getElementById("test").classList.contains("incorrect"))) return;
	
	play_sounds("select");
	
	const item_rect = el.getBoundingClientRect();
	
	const offsetX = clientX - item_rect.left;
	const offsetY = clientY - item_rect.top;
	
	el.style.width = `${item_rect.width}px`;
	el.style.height = `${item_rect.height}px`;
	el.style.left = `${item_rect.left}px`;
	el.style.top = `${item_rect.top}px`;

	el.dataset.offsetX = offsetX;
	el.dataset.offsetY = offsetY;

	el.classList.add("dragging");

	dragged_item=el;
}
function handle_mouseup(e)
{	
	if (!dragged_item) return;

	e.preventDefault();

	const clientX = e.clientX || e.changedTouches[0].clientX;
    const clientY = e.clientY || e.changedTouches[0].clientY;
                
	//const touch = e.changedTouches[0];  pageX, pageY 
	dragged_item.style.display = 'none';
	const elements=document.elementsFromPoint(clientX, clientY);
	if(elements)
	{
		const container = elements.find(el => el.classList.contains('order_result')); 
		//document.elementFromPoint(clientX, clientY);
		dragged_item.style.display = '';

		if((container) && (container.classList.contains("order_result")))
		{
			container.appendChild(dragged_item);
		}
	}

	el.classList.remove("dragging");

	dragged_item.style.left = '';
	dragged_item.style.top = '';
	dragged_item.style.width = '';
	dragged_item.style.height = '';
		
	delete dragged_item.dataset.offsetX;
	delete dragged_item.dataset.offsetY;
		
	dragged_item = null;

	if(document.getElementById("test").querySelector(".answers_row .answer")==null)
		document.getElementById("test_footer").classList.add("ready");
}
function handle_mousemove(e)
{
	if (!dragged_item) return;

	e.preventDefault();

	const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;
	
	//const touch = e.targetTouches[0];
	const offsetX = parseInt(dragged_item.dataset.offsetX);
	const offsetY = parseInt(dragged_item.dataset.offsetY);

	//dragged_item.style.position = 'fixed';

	dragged_item.style.left = `${clientX - offsetX}px`;
	dragged_item.style.top = `${clientY - offsetY}px`;

	//dragged_item.style.transform = 'translate(-50%, -50%)';
    //dragged_item.style.pointerEvents = 'none';
}
*/
let dragged_item = null;
let lastKnownPos = { x: 0, y: 0 };
let animationFrameId = null;

function updateElementPosition(clientX, clientY) {
    if (!dragged_item) return;
    
    const offsetX = parseInt(dragged_item.dataset.offsetX);
    const offsetY = parseInt(dragged_item.dataset.offsetY);
    
    dragged_item.style.left = `${clientX - offsetX}px`;
    dragged_item.style.top = `${clientY - offsetY}px`;
}

function handle_mousedown(e) {
    e.preventDefault();
    
    const el = e.target;
    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;

    if((document.getElementById("test").classList.contains("correct")) ||
        (document.getElementById("test").classList.contains("incorrect"))) return;
    
    play_sounds("select");
    
    const item_rect = el.getBoundingClientRect();
    
    const offsetX = clientX - item_rect.left;
    const offsetY = clientY - item_rect.top;
    
    el.style.width = `${item_rect.width}px`;
    el.style.height = `${item_rect.height}px`;
    el.style.left = `${item_rect.left}px`;
    el.style.top = `${item_rect.top}px`;
    el.style.position = 'fixed'; // Añadimos position fixed aquí

    el.dataset.offsetX = offsetX;
    el.dataset.offsetY = offsetY;

    el.classList.add("dragging");
    
    dragged_item = el;
    lastKnownPos = { x: clientX, y: clientY };
    
    // Iniciamos el loop de animación
    if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
    }
    animationFrameId = requestAnimationFrame(updateDragPosition);
}

function handle_mouseup(e) {
    if (!dragged_item) return;

    e.preventDefault();

    // Cancelamos el loop de animación
    if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
        animationFrameId = null;
    }

    const clientX = e.clientX || e.changedTouches[0].clientX;
    const clientY = e.clientY || e.changedTouches[0].clientY;
                
    dragged_item.style.display = 'none';
    const elements = document.elementsFromPoint(clientX, clientY);
    dragged_item.style.display = '';

    if(elements) {
        const container = elements.find(el => el.classList.contains('order_result')); 
        
        if(container && container.classList.contains("order_result")) {
            container.appendChild(dragged_item);
        }
    }

    dragged_item.classList.remove("dragging");
    dragged_item.style.position = ''; // Restauramos la posición original
    dragged_item.style.left = '';
    dragged_item.style.top = '';
    dragged_item.style.width = '';
    dragged_item.style.height = '';
        
    delete dragged_item.dataset.offsetX;
    delete dragged_item.dataset.offsetY;
        
    dragged_item = null;
    lastKnownPos = { x: 0, y: 0 };

    if(document.getElementById("test").querySelector(".answers_row .answer") == null) {
        document.getElementById("test_footer").classList.add("ready");
    }
}

function handle_mousemove(e) {
    if (!dragged_item) return;

    e.preventDefault();

    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;
    
    // Actualizamos la última posición conocida
    lastKnownPos = { x: clientX, y: clientY };
}

function updateDragPosition() {
    if (dragged_item && lastKnownPos) {
        updateElementPosition(lastKnownPos.x, lastKnownPos.y);
    }
    
    if (dragged_item) {
        animationFrameId = requestAnimationFrame(updateDragPosition);
    }
}


// Añadir los event listeners para touch si es necesario
function handle_drag_start(e,content)
{
	const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;

	const el=e.target;

	const item_rect = el.getBoundingClientRect();
                
	//const touch = e.touches[0];
	const offsetX = clientX - item_rect.left;
	const offsetY = clientY - item_rect.top;
	
	const placeholder = document.createElement('div');
	placeholder.classList.add('placeholder');
	placeholder.style.height = `${el.offsetHeight}px`;
	placeholder.style.width = `${el.offsetWidth}px`;
	content.insertBefore(placeholder,el);

	//console.log(placeholder.style.width,placeholder.style.height);

	el.style.width = `${item_rect.width}px`;
	el.style.height = `${item_rect.height}px`;
	el.style.left = `${item_rect.left}px`;
	el.style.top = `${item_rect.top}px`;

	el.dataset.offsetX = offsetX;
	el.dataset.offsetY = offsetY;

	el.classList.add("dragging");

	return({placeholder:placeholder,draggingElement:el});
}
function handle_drag_over(e,container,dragged_item,placeholder)
{
	const pageX = e.pageX || e.touches[0].pageX;
	const pageY = e.pageY || e.touches[0].pageY;

	const offsetX = parseInt(dragged_item.dataset.offsetX);
	const offsetY = parseInt(dragged_item.dataset.offsetY);
		
	dragged_item.style.left = `${pageX - offsetX}px`;
	dragged_item.style.top = `${pageY - offsetY}px`;

	const clientX = e.clientX || e.touches[0].clientX;
	const clientY = e.clientY || e.touches[0].clientY;
	const items = Array.from(container.querySelectorAll('.answer:not(.dragging)'));
	
	let closestItem = null;
	let closestOffset = Number.NEGATIVE_INFINITY;

	if(container.hasAttribute("aria-horizontal"))
	{
		items.forEach(item => {
			const { left, width } = item.getBoundingClientRect();
			const offset = clientX - left - width / 2;
	
			if (offset < 0 && offset > closestOffset) {
				closestOffset = offset;
				closestItem = item;
			}
		});
	}else{
		items.forEach(item => {
			const { top, height } = item.getBoundingClientRect();
			const offset = clientY - top - height / 2;
	
			if (offset < 0 && offset > closestOffset) {
				closestOffset = offset;
				closestItem = item;
			}
		});	
	}

	// Removemos el placeholder existente
	if (placeholder.parentNode)
		container.removeChild(placeholder);
	
	if (closestItem)
		container.insertBefore(placeholder, closestItem);
	else
		container.appendChild(placeholder);
}

function handle_drag_end(e,dragged_item,placeholder) {
	e.preventDefault();
	//return;
	if (!dragged_item) return;
	if (placeholder && placeholder.parentNode) {

		dragged_item.classList.remove("dragging");

		dragged_item.style.left = '';
		dragged_item.style.top = '';
		dragged_item.style.width = '';
		dragged_item.style.height = '';
			
		delete dragged_item.dataset.offsetX;
		delete dragged_item.dataset.offsetY;

		placeholder.parentNode.replaceChild(dragged_item, placeholder);
	}
}

function normalize_text(text)
{
	return(text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase());
}

function set_values(values)
{
	if(!values) return;
	for(let k in values)
	{
		document.querySelectorAll("."+k).forEach(el=>
		{
			if(el.tagName.toLowerCase()==='input' ||  el.tagName.toLowerCase()==='textarea' || el.tagName.toLowerCase()==='select')
				el.value=values[k];
			else
				el.innerHTML=values[k];
		});
		player.values[k]=values[k];
	}

	// avatar
	if("player_avatar" in values)
	{	
		document.getElementById("profile_avatar").setAttribute("aria-value",values.player_avatar);
		const selected=document.querySelector(`.avatar_grid .avatar_item[aria-id='${values.player_avatar}']`);
		document.getElementById("profile_avatar").src=selected.querySelector("img").src;
		selected.classList.add("selected");
	}

	if(values.player_avatars)
	{
		const items=document.querySelectorAll(".avatar_grid .avatar_item");
		for(let k of items)
		{
			if(values.player_avatars.includes(parseInt(k.getAttribute("aria-id"))))
			{
				k.setAttribute("selectable","");
				k.querySelector(".btn_buy_avatar").classList.add("hide");
			}else{
				k.removeAttribute("selectable");
			}
		}
	}

	if(values.player_library)
	{
		const library=document.getElementById("library_items");
		library.innerHTML="";
		console.log(values.player_library);
		for (const key in values.player_library)
		{
			const subject=document.querySelector("#elements .library_subject").cloneNode(true);
			const grid=subject.querySelector(".library_grid");
			for (const i in values.player_library[key])
			{	
	
				const item=document.querySelector("#elements .library_item").cloneNode(true);
				item.querySelector("img").setAttribute("src",values.player_library[key][i].lib_file);
				item.setAttribute("aria-id",values.player_library[key][i].lib_id);
				grid.append(item);
			}
			subject.querySelector(".library_subject_title").innerHTML=kivi.subjects[key].name;
			subject.querySelector(".library_subject_items").innerHTML=values.player_library[key].length;
			library.append(subject);
		}

		library.querySelectorAll("[aria-to-section]").forEach(el=>
		{
			el.addEventListener("click",e=>{
				e.preventDefault();
				screen_functions["show_screenshot"](el);
			});
		});

		document.getElementById("library_empty").classList.add("hide");
		document.getElementById("library_items").classList.remove("hide");
	}else{
		document.getElementById("library_empty").classList.remove("hide");
		document.getElementById("library_items").classList.add("hide");
	}
}

function show_alert(text,err=true,question=false,func_yes=null,func_no=null,reverse=false)
{
	const popup=document.getElementById("alert_popup");
	
	popup.querySelectorAll(".no_question").forEach(el=>{
		if(question)
			el.classList.add("hide");
		else 
			el.classList.remove("hide");
	});
	popup.querySelectorAll(".question").forEach(el=>{
		if(question)
			el.classList.remove("hide");
		else 
			el.classList.add("hide");
	});
	if(question)
	{
		const btn_yes=popup.querySelector(".btn_yes");
		const btn_no=popup.querySelector(".btn_no");

		btn_yes.setAttribute("href",func_yes);
		btn_no.setAttribute("href",func_no);
		if(reverse)
		{
			btn_yes.classList.remove("btn_white");
			btn_no.classList.add("btn_white");
		}else{
			btn_yes.classList.add("btn_white");
			btn_no.classList.remove("btn_white");
		}
	}
	popup.querySelector(".alert_msg").innerHTML=text;
	if(err)
		popup.classList.add("error");
	else 
		popup.classList.remove("error");
	document.querySelector(".alert_background").classList.remove("hide");
	popup.classList.add("show");
}

function format_time(seconds)
{
	let result={};
	if (seconds<60) {
        result={value:seconds, unit:0};
    }else if (seconds<3600) {result={value:(seconds/60).toFixed(0),unit:1};
    	}else if (seconds<86400) {result={value:(seconds/3600).toFixed(1),unit:2};
    		}else{
        		result={value:(seconds/86400).toFixed(1),unit:3};
    }

    return result;
}

function set_ranking(ranking)
{
	const list=document.getElementById("ranking_list");
	list.innerHTML="";
	if(!ranking) return;

	for(let a in ranking)
	{
		console.log(ranking[a]);
		const item=document.querySelector(`#elements .ranking_item`).cloneNode(true);
		item.querySelector(".ranking_rank").innerHTML=ranking[a].rank;
		item.querySelector(".ranking_avatar").setAttribute("src",
			document.querySelector(`.avatar_item[aria-id="${ranking[a].user_avatar}"] img`).getAttribute("src"));
		if(ranking[a].user_name)
		{
			item.querySelector(".ranking_name").innerHTML=ranking[a].user_name;
		}else{

		}

		list.append(item);
	}
}

function animate_counter(element, start, end, duration) {
	let startTime = null;

	function step(timestamp) {
	  if (!startTime) startTime = timestamp;
	  const progress = Math.min((timestamp - startTime) / duration, 1);
	  element.textContent = Math.floor(progress * (end - start) + start);

	  if (progress < 1) {
		window.requestAnimationFrame(step);
	  }
	}

	window.requestAnimationFrame(step);
  }

function check_screenshot(data,test,question)
{
	for(let a in data)
	{
		if(data[a].lib_test==test && data[a].lib_question==question) return(true);
	}
	return(false);
}

function preload_image(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = () => resolve(null);
    });
}

function preload_images(images,delay=false) 
{
	const promises = images.map(preload_image);
	if(delay)
	{
		 const minimum_delay=new Promise((resolve) => setTimeout(resolve, 350));
		 return Promise.all([minimum_delay, ...promises]);
	}
   
    return Promise.all(promises);  
}

function get_imgs_questions(data)
{
	const list=[];
	const fields=["primary_img_id",
				  "a1_img_id","a2_img_id","a3_img_id","a4_img_id","a5_img_id",
				  "a6_img_id","a7_img_id","a8_img_id","a9_img_id","a10_img_id"
				];

	const subject=kivi.units[kivi.apartats[kivi.tests[player.current_test].apartat_id].unit_id].subject_id;
	
	for(let q of data)
	{
		for(let f of fields)
		{
			if(q[f])
				list.push(`${image_path+subject}/${subject}_CONTENT/${q[f]}.png`);
		}
	}
	return(list);
}

// Auth functions

let auth_init_done=false;
function init_google_auth()
{
	google.accounts.id.initialize({
		client_id: '316778525804-1tfj3d9eeaqt3jt8bkr1f3mj257flbcb.apps.googleusercontent.com',
		callback: handle_google_response,
		context:"use",
		ux_mode:"popup",
		auto_select:false,
		auto_prompt:false,
		use_fedcm_for_prompt:true,
	  });
	google.accounts.id.renderButton(
		document.getElementById("btn_auth_google"),
		{
			type:"icon",
			shape:"circle",
			theme:"outline",
			text:"signin_with",
			size:"large"
		}
	);
	google.accounts.id.prompt();
}

function init_apple_auth()
{
	document.addEventListener('AppleIDSignInOnSuccess', handle_apple_response);
	document.addEventListener('AppleIDSignInOnFailure', handle_apple_response);

	AppleID.auth.init({
        clientId: 'com.kivischool.auth',
        scope: 'email',
        redirectURI: "https://app.kivischool.com", //'https://server.kivischool.com/auth/apple',
		state : 'SignInUserAuthenticationRequest',
        //state: 'origin:web',
        usePopup: true
    });
}
async function handle_google_response(response) 
{
	const ret=await server_function("auth/google",{credential:response.credential});
	if(ret && ret.status)
	{
		localStorage.kivi_session=ret.token;
		screen_functions["start_register"]();
	}else{ 
		console.log("Error");
	}
}

async function handle_apple_response(event)
{
    //console.log("callback:",event);
	
	if(event.detail && event.detail.authorization)
	{
		const ret=await server_function("auth/apple",{data:event.detail.authorization});
		console.log("result:",ret)
		if(ret && ret.status)
		{
			localStorage.kivi_session=ret.token;
			screen_functions["start_register"]();
		}else{ 
			console.log("Error");
		}
	}
};

document.addEventListener("DOMContentLoaded", load, false);

