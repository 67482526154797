/*const server_url= location.hostname === "localhost" 
? "http://localhost:8080/"
: "https://server.kivischool.com/";
*/
const is_cordova = typeof window.cordova !== "undefined";
const server_url = is_cordova
    ? "https://server.kivischool.com/" 
    : (location.hostname === "localhost" 
        ? "http://localhost:8080/" 
        : "https://server.kivischool.com/");

export { server_url };

export async function server_function(func,params={},ret=null,session=true,form=null)
{
	let json;
	try
	{
		
        const options={
            method: 'post',
            mode: "cors",
            //credentials: 'include',
        }
        if(form)
        {
            if((session) && (localStorage.kivi_session))
    			form.append("token",localStorage.kivi_session);

            options.body=form;
        }else{
            if((session) && (localStorage.kivi_session))
	    		params.token=localStorage.kivi_session;

            options.headers= {'Accept': 'application/json','Content-Type': 'application/json'};
            options.body=JSON.stringify(params);
        }

		const res = await fetch(server_url+func, options);	
		json=await res.json();
		if(!json) throw new Error("server_error");
	}catch(e){
		if(!ret) return(false);
		ret(false);
		return;
	}
	if(!ret) return(json);
	ret(json);
	return;
}


export async function load_data(sheet)
{
    //const document="1w7AK7GME9HBDIy7lGZkIW0E55RxgnnDJMz9Zfuorn9g";
	const document="1EvDoEnNQZttsH5OVB7m4kG_ViRSCjQbHhYNA214Pi9w";
    const url = "https://sheets.googleapis.com/v4/spreadsheets/" + document + "/values/"+
    sheet+"?alt=json"+"&key=AIzaSyD8c-oT4E9kvfAvrgUFOsm0b9wdDYOEI34";
    try{
        const res=await fetch(url);
        const data=await res.json();
        return(to_json(data.values));
    }catch(e)
    {
        console.log("Error loading data: ",e);
        return(null);
    }
}


export async function login_user(email)
{

}
export async function register_user(email,date,state)
{

}

function to_json(data)
{
    let res={};
    for(let a=1;a<data.length;a++)
    {
		console.log("DATA: ",data[a][0]);
		if(!data[a][0]) break;

        let i=data[a][0].replace(/(\r\n|\n|\r)/gm,"").trim();
        const d={};
        for(let b=1;b<data[0].length;b++)
        {
            d[data[0][b]]=data[a][b];
        }
        if(res[i])
        {
            if(!Array.isArray(res[i]))
            {
                const b=res[i];
                res[i]=[b];
            }
            res[i].push(d);
        }else   
            res[i]=d;
    }
    return(res);
}